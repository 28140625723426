import { motion } from "framer-motion";
import { ReactComponent as ArrowBackICON } from "../../images/icons/arrow-back.svg";
import { ReactComponent as CheckICON } from "../../images/icons/check.svg";
import styles from "./Navigation.module.css";

export const Navigation = ({
  icons,
  isActive,
  currentStep,
  totalSteps,
  firstStep,
  lastStep,
  nextStep,
  previousStep,
  goToStep,
  goToNamedStep,
}) => {
  const handleClick = (step) => () => {
    goToStep(step);
  };

  const ignored = [1, totalSteps];

  // Ignoring first and last screen
  const showNavigation = !ignored.includes(currentStep);

  return (
    showNavigation && (
      <motion.div className={styles.wrapper}>
        <ArrowBackICON onClick={previousStep} className={styles.closeBtn} />
        {Array.from(
          { length: totalSteps - ignored.length },
          (v, k) => k + ignored.length
        ).map((step) => {
          const StepIcon = icons[step - ignored.length];
          // StepIcon.props.fill = step === currentStep ? "#fff" : "#CFD1D3";

          return (
            <Step
              key={step}
              icon={StepIcon}
              isActive={step <= currentStep}
              isCurrent={step === currentStep}
              onClick={handleClick(step)}
            />
          );
        })}
      </motion.div>
    )
  );
};

const Step = ({ icon, isActive, isCurrent, onClick }) => {
  return (
    <motion.div
      onClick={onClick}
      className={`${styles.step} ${isCurrent ? styles.isActive : ""} ${
        isActive ? styles.isDone : ""
      }`}
    >
      <CheckICON />
      {isActive && !isCurrent && (
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: 2,
            },
            visible: {
              opacity: 1,
              y: 2,
            },
          }}
          initial={"visible"}
          animate={"hidden"}
          transition={{ duration: 1 }}
        >
          <CheckICON />
        </motion.div>
      )}
      {icon}
    </motion.div>
  );
};
