import { AvoInspector, AvoInspectorEnv } from "avo-inspector";
import * as rudderanalytics from "rudder-sdk-js";
import pkgJson from "../../package.json";
import Avo, { Platform, avoInspectorApiKey } from "./Avo";

const { version } = pkgJson;

class EventTracking {
  apiKey = avoInspectorApiKey;
  appName = "Web Tutorial";
  env = AvoInspectorEnv.Dev;
  platform = Platform.WEB;
  version = version;
  inspector;

  constructor() {
    this.platform = ((search) => {
      const param = new URLSearchParams(search).get("platform");
      if (param && param.match(/ios/gi)) return Platform.I_O_S;
      if (param && param.match(/android/gi)) return Platform.ANDROID;
      return Platform.WEB;
    })(window.location.search);

    const env = (this.env = ((hostname) => {
      if (hostname.match(/staging.mileiq.dev/gi))
        return AvoInspectorEnv.Staging;
      if (hostname.match(/mileiq.com/gi)) return AvoInspectorEnv.Prod;
      return AvoInspectorEnv.Dev;
    })(window.location.hostname));

    const inspector = (this.inspector = new AvoInspector({
      apiKey: this.apiKey,
      appName: this.appName,
      env: this.env,
      version: this.version,
    }));

    Avo.initAvo(
      { env, inspector },
      null,
      {},
      // Rudderstack
      {
        make: (env, key) => {
          const opts = {};
          if (env !== "prod") opts.logLevel = "DEBUG";
          rudderanalytics.load(
            key,
            "https://mileiqjoeplpv.dataplane.rudderstack.com",
            opts
          );
        },
        logEvent: (eventName, eventProperties) => {
          rudderanalytics.track(eventName, eventProperties);
        },
      }
    );
  }

  track = (eventName, props) => {
    Avo[eventName](
      Object.assign(
        {
          platform: this.platform,
          version: this.version,
        },
        props
      )
    );
  };
}

export const track = new EventTracking().track;
