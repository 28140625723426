import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import StepWizard from "react-step-wizard";
import { Navigation } from "./components/navigation";

import CardA from "./images/cards/cardA.png";
import CardB from "./images/cards/cardB.png";
import { ReactComponent as BusinessPurposeICON } from "./images/icons/business-purpose.svg";
import { ReactComponent as BusinessICON } from "./images/icons/business.svg";
import { ReactComponent as CheckICON } from "./images/icons/check.svg";
import { ReactComponent as PersonalPurposeICON } from "./images/icons/personal-purpose.svg";
import { ReactComponent as PersonalICON } from "./images/icons/personal.svg";
import { ReactComponent as TagICON } from "./images/icons/tag.svg";
import { ReactComponent as PersonalICONX } from "./images/icons/u_home.svg";
import { ReactComponent as BusinessICONX } from "./images/icons/u_suitcase.svg";
import { ReactComponent as UndoOnlyICON } from "./images/icons/undo-only.svg";
import { ReactComponent as UndoICON } from "./images/icons/undo.svg";

import { ReactComponent as CardPurposeBusiness } from "./images/business_purpose.svg";
import { ReactComponent as GetStartedSVG } from "./images/getstarted.svg";
import { ReactComponent as CardPurposePersonal } from "./images/personal_purpose.svg";
import { ReactComponent as SwipeLeftTooltip } from "./images/swiping-hand-left.svg";
import { ReactComponent as SwipeRightTooltip } from "./images/swiping-hand-right.svg";

import styles from "./App.module.css";
import { track } from "./tracking";

let Card01IMG, Card02IMG, Card03IMG, Card04IMG, Card05IMG;
Card01IMG = Card03IMG = Card05IMG = CardA;
Card02IMG = Card04IMG = CardB;

const close = () => {
  const param = new URLSearchParams(window.location.search).get("platform");

  if (param.match(/ios/gi)) {
    window.webkit.messageHandlers.jsMessageHandler.postMessage(
      "mileIQTurorialFinished"
    );
  }
  if (param.match(/android/gi)) {
    window.webViewCloser.closeActivity();
  }
};

track("onboardingStarted", { page: "Tutorial" });

function App() {
  const constraints = 100;
  const steps = [
    {
      hashKey: "classify-as-business",
      backgroundColor: "#EAF6FD",
      navIcon: <BusinessICON />,
      ribbonIcon: <BusinessICONX />,
      card: Card01IMG,
      label: "Business",
      ribbon: "left",
      stepName: (
        <>
          <strong>Swipe right</strong> to classify as Business
        </>
      ),
      tip: "right",
      onDragEnd: (e, info) => {
        const swipeRight = info.offset.x > constraints;
        if (swipeRight) {
          return {
            x: `calc(100vw - 20px)`,
          };
        }
      },
    },
    {
      hashKey: "classify-as-personal",
      backgroundColor: "#FFFBF3",
      navIcon: <PersonalICON />,
      ribbonIcon: <PersonalICONX />,
      card: Card02IMG,
      label: "Personal",
      ribbon: "right",
      stepName: (
        <>
          Now <strong>swipe left</strong> to classify as Personal
        </>
      ),
      tip: "left",
      onDragEnd: (e, info) => {
        const swipeLeft = info.offset.x < -constraints;
        if (swipeLeft) {
          return {
            x: `calc(-100vw + 20px)`,
          };
        }
      },
    },
    {
      hashKey: "classify-as-business-purpose",
      backgroundColor: "#F0FCED",
      navIcon: <BusinessPurposeICON />,
      ribbonIcon: <BusinessICONX />,
      card: Card03IMG,
      label: "Business",
      ribbon: "left",
      stepName: (
        <>
          <strong>Swipe right and hold</strong> to add a business purpose
        </>
      ),
      tip: "right",
      isClassifyBusinessPurpose: true,
      onDragEnd: (e, info) => {
        const swipeRight = info.offset.x > constraints * 1.7;
        const hold = true; // TODO: add hold
        if (swipeRight && hold) {
          return {
            x: `calc(100vw - 20px)`,
          };
        }
      },
    },
    {
      hashKey: "classify-as-personal-purpose",
      backgroundColor: "#FFF7F5",
      navIcon: <PersonalPurposeICON />,
      ribbonIcon: <PersonalICONX />,
      card: Card04IMG,
      label: "Personal",
      ribbon: "right",
      stepName: (
        <>
          <strong>Swipe left and hold</strong> to add a personal purpose
        </>
      ),
      tip: "left",
      isClassifyPersonalPurpose: true,
      onDragEnd: (e, info) => {
        const swipeLeft = info.offset.x < -constraints * 1.7;
        const hold = true; // TODO: add hold
        if (swipeLeft && hold) {
          return {
            x: `calc(-100vw + 20px)`,
          };
        }
      },
    },
    {
      hashKey: "easily-undo",
      backgroundColor: "#F1F4FD",
      navIcon: <UndoICON />,
      ribbonIcon: <UndoICON />,
      card: Card05IMG,
      stepName: (
        <>
          <strong>Easily undo</strong> any changes
        </>
      ),
      showFooter: true,
    },
  ];

  const handleStepChange = ({ previousStep, activeStep }) => {
    const stepNames = [
      "Started",
      "Classify as Business",
      "Classify as Personal",
      "Choose Business Purpose",
      "Choose Personal Purpose",
      "Undo",
    ];
    const isBack = activeStep - previousStep < 0;
    if (isBack) {
      track("onboardingNavBack", {
        page: "Tutorial",
        tutorialStepName: stepNames[previousStep - 1],
      });
    } else {
      track("onboardingStepCompleted", {
        page: "Tutorial",
        tutorialStepName: stepNames[previousStep - 1],
      });
    }
  };

  return (
    <StepWizard
      isHashEnabled={true}
      nav={<Navigation icons={steps.map(({ navIcon: icon }) => icon)} />}
      transitions={{
        enterRight: "xanimate__enterRight",
        enterLeft: "xanimate__enterLeft",
        exitRight: "xanimate__exitRight",
        exitLeft: "xanimate__exitLeft",
        intro: "xanimate__intro",
      }}
      onStepChange={handleStepChange}
    >
      <StartScreen hashKey={"start"} />
      {steps.map((step) => (
        <Step {...step} key={step.hashKey} />
      ))}
      <FinishScreen hashKey={"done"} />
    </StepWizard>
  );
}

export default App;

const Step = (step) => {
  const { stepName, isActive } = step;
  const [showFooter, setShowFooter] = useState(step.showFooter);
  const [tip, setTip] = useState(step.tip);
  const [isRibbonSuccess, setIsRibbonSuccess] = useState(false);

  const scene = {
    animate: useAnimation(),
    initial: "hidden",
    transition: { delay: 0, duration: 0.8 },
    variants: {
      hidden: { opacity: 0, y: 30 },
      show: {
        opacity: 1,
        y: 0,
      },
    },
  };

  const interaction = {
    animate: useAnimation(),
    initial: "start",
    transition: { delay: 0, duration: 0.2 },
    variants: {
      start: {
        opacity: 1,
        x: 0,
      },
      success: {
        opacity: 0,
        transition: {
          duration: 1,
        },
      },
      undoStart: {
        opacity: 0,
        x: -400,
      },
      undoEnd: {
        opacity: 1,
        x: 0,
        transition: {
          duration: 1,
        },
      },
    },
  };

  const purpose = {
    animate: useAnimation(),
    initial: "hidden",
    transition: { delay: 0.1, duration: 0.4 },
    variants: {
      hidden: {
        zIndex: -1,
        opacity: 0,
        bottom: -100,
        pointerEvents: "none",
      },
      visible: {
        zIndex: 0,
        opacity: 1,
        bottom: 0,
        pointerEvents: "all",
      },
      success: {
        opacity: 0,
      },
    },
  };

  const isAddPurpose =
    isRibbonSuccess &&
    (step.isClassifyPersonalPurpose || step.isClassifyBusinessPurpose);

  useEffect(() => {
    if (isActive) {
      interaction.animate.set(
        step.showFooter ? "undoStart" : interaction.initial
      );
      purpose.animate.set(step.showFooter ? "undoStart" : purpose.initial);
      setTip(step.tip);
      scene.animate.start("show");
    } else {
      scene.animate.start("hidden");
    }
  }, [
    scene.animate,
    step,
    isActive,
    stepName,
    interaction.animate,
    interaction.initial,
    purpose.animate,
    purpose.initial,
    setTip,
  ]);

  const handleDrag = (interaction) => (e, info) => {
    const success = step.onDragEnd(e, info);
    setIsRibbonSuccess(!!success);
  };

  const handleDragEnd = (interaction) => (e, info) => {
    const success = step.onDragEnd(e, info);
    if (!success) {
      interaction.animate.start("start");
    } else {
      interaction.animate
        .start(
          { ...success, opacity: 0.8, transition: { duration: 0.6 } },
          null,
          2
        )
        .then(() =>
          interaction.animate.start({
            opacity: 0,
            transition: { duration: 0.5 },
          })
        )
        .then(() => {
          if (
            step.isClassifyBusinessPurpose ||
            step.isClassifyPersonalPurpose
          ) {
            purpose.animate.start("visible");
          } else {
            scene.animate.start("hidden").then(step.nextStep);
          }
        });
    }
  };

  const handlePurposeSelect = (e) => {
    track("onboardingStepCompleted", {
      page: "Tutorial",
      tutorialStepName: `Add a ${step.label} Purpose`,
    });
    purpose.animate.start("success").then(step.nextStep);
  };

  const handleUndo = () => {
    setShowFooter(false);
    interaction.animate.start("undoEnd").then(() => {
      scene.animate.start("hidden").then(step.nextStep);
    });
  };

  const handleDismissTip = () => {
    setTip(null);
  };

  return (
    <Container {...step}>
      <motion.div
        variants={scene.variants}
        initial={scene.initial}
        animate={scene.animate}
        transition={{ ...scene.transition, delayChildren: 0.3 }}
      >
        <motion.div
          variants={scene.variants}
          initial={scene.initial}
          animate={scene.animate}
          transition={scene.transition}
        >
          <h1 className="steptitle">{stepName}</h1>
        </motion.div>
        <motion.div
          style={{ scale: 1.12 }}
          variants={interaction.variants}
          initial={step.showFooter ? "undoStart" : interaction.initial}
          animate={interaction.animate}
          transition={interaction.transition}
          drag={step.showFooter ? false : "x"}
          whileDrag={
            step.showFooter
              ? false
              : { transition: { duration: 0 }, scale: 1.12 || 1.02 }
          }
          whileTap={step.showFooter ? false : { scale: 1.12 || 1 }}
          // dragConstraints={step.showFooter ? false : { left: 0, right: 0 }}
          onDragStart={handleDismissTip}
          onDrag={step.showFooter ? false : handleDrag(interaction)}
          onDragEnd={step.showFooter ? false : handleDragEnd(interaction)}
        >
          <img src={step.card} alt="Drive Card" />
          <motion.div
            style={{
              pointerEvents: "none",
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
            }}
            initial={{ filter: "grayscale(1)", opacity: 0.6 }}
            animate={
              isRibbonSuccess
                ? { filter: "grayscale(0)", opacity: 1 }
                : { filter: "grayscale(1)", opacity: 0.6 }
            }
            transition={{ duration: 0.3 }}
          >
            <BlueRibbon
              icon={
                isAddPurpose ? (
                  <i
                    style={{
                      fontStyle: "normal",
                      fontWeight: "600",
                      display: "flex",
                      width: "72px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <TagICON style={{ transform: "translate(1px, 2px)" }} /> +{" "}
                    {step.ribbonIcon}
                  </i>
                ) : (
                  <i>{step.ribbonIcon}</i>
                )
              }
              label={isAddPurpose ? "Add a Purpose" : step.label}
              position={step.ribbon}
              isRibbonSuccess={isRibbonSuccess}
            />
          </motion.div>
        </motion.div>
        <motion.div
          className={styles.purposeCard}
          variants={purpose.variants}
          initial={step.showFooter ? "undoStart" : purpose.initial}
          animate={purpose.animate}
          transition={purpose.transition}
        >
          {step.isClassifyBusinessPurpose && (
            <CardPurposeBusiness onTouchStart={handlePurposeSelect} />
          )}
          {step.isClassifyPersonalPurpose && (
            <CardPurposePersonal onTouchStart={handlePurposeSelect} />
          )}
        </motion.div>
        <motion.div
          variants={{
            hide: {
              opacity: 0,
              pointerEvents: "none",
              transition: { delay: 0.5 },
            },
            show: { opacity: 1, pointerEvents: "all" },
          }}
          initial={"hide"}
          animate={isActive && showFooter ? "show" : "hide"}
          transition={{ duration: 0.3, delay: 1 }}
        >
          <Footer handleUndo={handleUndo} />
        </motion.div>
      </motion.div>
      {step.tip && (
        <motion.div
          style={{ pointerEvents: "none" }}
          initial={{ opacity: 1 }}
          animate={{ opacity: tip ? 1 : 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <SwipeTooltip
            animating={step.currentStep && step.isActive && tip}
            left={step.tip === "left"}
          />
        </motion.div>
      )}
    </Container>
  );
};

const BlueRibbon = ({ icon, label, position }) => {
  return (
    <div
      className={`${styles.blueRibbon} ${
        styles["blueRibbon-" + position] || ""
      }`}
    >
      <div>
        {icon}
        {label}
      </div>
    </div>
  );
};

const Container = ({ children, style, backgroundColor }) => {
  return (
    <div className={`step-container ${styles.container}`} style={style}>
      <span style={{ background: backgroundColor }} />
      {children}
    </div>
  );
};

const SwipeTooltip = ({ left, animating }) => {
  return (
    <motion.div
      style={{
        pointerEvents: "none",
        position: "absolute",
        top: "50vh",
        left: "35vw",
        width: 70,
        height: 80,
      }}
      animate={!animating ? null : { rotate: 20, x: 50, y: 5 }}
      transition={
        !animating
          ? { ease: "easeInOut", duration: 1, repeat: 0 }
          : {
              ease: "easeInOut",
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
            }
      }
    >
      {left ? <SwipeLeftTooltip /> : <SwipeRightTooltip />}
    </motion.div>
  );
};

function StartScreen({ nextStep }) {
  return (
    <Container
      backgroundColor={
        "linear-gradient(180deg, rgba(234,246,253,1) 0%, rgba(255,255,255,1) 100%)"
      }
    >
      <div className={styles.introsWrapper}>
        <div>
          <h1>Let's get familiar</h1>
          <h3>
            Here's everything you need
            <br /> to know to get started
          </h3>
        </div>
        <div style={{ transform: "translateX(-65px)" }}>
          <GetStartedSVG />
        </div>
        <button onTouchStart={nextStep}>Start tutorial</button>
      </div>
    </Container>
  );
}

function Footer({ handleUndo }) {
  return (
    <footer>
      <button style={{ visibility: "hidden" }}>
        <UndoOnlyICON />
      </button>
      <h4 style={{ textAlign: "center", fontWeight: "normal" }}>
        Drive to Job Site
        <br />
        classified as Business
      </h4>
      <button onTouchStart={handleUndo}>
        <UndoOnlyICON />
      </button>
    </footer>
  );
}

function FinishScreen({ isActive, currentStep }) {
  useEffect(() => {
    if (isActive)
      setTimeout(() => {
        track("onboardingCompleted", { page: "Tutorial" });
        close();
      }, 1500);
  }, [isActive]);
  return (
    <Container style={{ paddingBottom: "18vh" }} backgroundColor={"#F1F4FD"}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={isActive && currentStep && { opacity: 1 }}
        transition={{ duration: 0.3 }}
        className={styles.introsWrapper}
        style={{ justifyContent: "center" }}
      >
        <div>
          <i className={styles.successFinalDot}>
            <CheckICON fill={"#fff"} />
          </i>
          <h1>You're all set!</h1>
        </div>
      </motion.div>
    </Container>
  );
}
